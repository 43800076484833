@media (max-width: 1169px) {
    .site-header {
        padding: 20px 15px;
    }

    .site-header.fixed {
        padding: 20px 15px;
    }

    .hambergar {
        width: 35px;
        height: 35px;
        position: absolute;
        z-index: 3;
        display: inline-block;
        top: 50px;
        right: 20px;
        cursor: pointer;
    }

    .hambergar span {
        width: 100%;
        height: 4px;
        float: right;
        background: var(--primary);
        margin: 0 0 7px;
        border-radius: 3px;
        -webkit-transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
    }

    .hambergar span:last-child {
        margin: 0;
    }

    .navigation-wrapper li {
        width: 100%;
        text-align: center;
        margin: 0;
        border-bottom: 1px solid #ccc;
    }

    .navigation-wrapper .navigation {
        position: fixed;
        padding: 125px 0 0;
        width: 420px;
        max-width: 90%;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        height: 100vh;
        z-index: 2;
        opacity: 0;
        background: rgba(0, 0, 0, 0.9);
        box-shadow: 0 0 20px rgb(0 0 0 / 20%);

        -webkit-transition: all 0.5s ease 0s;
        -moz-transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        transform: translateX(100%);
    }

    .navigation-wrapper.open .navigation {
        opacity: 1;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        transform: translateX(0);
    }

    .navigation ul {
        display: initial;
        width: 100%;
    }

    .navigation ul li {
        width: 100%;
        text-align: center;
        margin: 0;
        border-bottom: 1px solid #ccc;
    }

    .navigation-wrapper li:first-child {
        border-top: 1px solid #ccc;
    }

    .navigation-wrapper li a:not(.btn) {
        width: 100%;
        display: inline-block;
        padding: 20px 10px;
        color: #fff;
        font-size: 16px;
    }
}

@media (max-width: 1024px) {
    .banner-content h1 {
        font-size: 60px;
    }

    .banner-content p {
        font-size: 20px;
    }

    .banner-img img {
        max-width: 450px;
    }
}

@media (max-width: 960px) {
    .banner-content h1 {
        font-size: 50px;
    }

    .banner-content p {
        font-size: 19px;
    }

    .download-btn-wrapper .btn {
        font-size: 16px;
        padding: 12px 20px;
    }

    .banner-img img {
        max-width: 100%;
    }

    .about-img img {
        max-width: 100%;
    }

    .about-content h2,
    .headings h2 {
        font-size: 28px;
    }

    .about-content p,
    .headings p {
        font-size: 18px;
    }

    .how-it-works-wrapper .headings {
        max-width: 80%;
    }

    .how-it-works-item h2 {
        font-size: 20px;
    }
}

@media (max-width: 767px) {

    .banner-wrapper {
        padding: 15px 0 85px;
    }

    .banner-img {
        margin: 0 0 25px;
    }

    .banner-img img {
        max-width: 90%;
    }

    .banner-content {
        text-align: center;
    }

    .banner-content p {
        max-width: 100%;
    }

    .banner-content h2 {
        font-size: 19px;
    }

    .banner-content h3 {
        font-size: 14px;
    }

    .download-btn-wrapper {
        justify-content: center;
    }

    .banner-wrapper .circle-img {
        bottom: -210px;
        left: 60px;
    }

    .banner-wrapper .circle-img img {
        max-width: 180px;
    }


    .about-img {
        margin: 0 0 35px;
    }

    .about-content {
        text-align: center;
    }

    .headings h2,
    .about-content h2 {
        font-size: 25px;
    }

    .headings p,
    .about-content p {
        font-size: 18px;
    }

    .site-header .social-icons {
        position: absolute;
        top: 42%;
    }

    .how-it-works-item {
        margin-bottom: 35px;
    }

    .footer-wrapper {
        text-align: center;
    }

    .mobile-footer-icons {
        margin: 10px 0 10px;
    }

    .footer-links {
        margin: 10px 0 10px;
        text-align: center;
    }

    .back-to-top {
        bottom: 45px;
        right: 25px;
    }

    .our-process-wrapepr {
        padding: 40px 0 40px 0;
    }

    .process-item {
        margin-bottom: 30px;
    }

    .process-wrap::after {
        display: none;
    }

    .faq-wrapper {
        padding: 50px 0 70px;
    }

    .faq-wrapper .headings {
        margin: 0 auto 25px;
    }

    .footer-wrapper .social-icons {
        justify-content: center;
        margin-top: 20px;
    }

    .how-it-works-item img {
        max-height: 400px;
    }
}