:root {
    --poppins: "Poppins";

    --fontawsome: "Font Awesome 5 Free";

    --white: #ffffff;
    --black: #000000;
    --light: #a0a0a0;
    --danger: #dc3545;

    --primary: #D98100;
    --secondary: #D8E7F9;
    --blue: #182139;
    --lightblue: #4B85A8;
    --redTitle: #811d2e;
}

/* Fonts */
@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-SemiBold.eot');
    src: url('../fonts/poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins/Poppins-SemiBold.woff') format('woff'),
        url('../fonts/poppins/Poppins-SemiBold.ttf') format('truetype'),
        url('../fonts/poppins/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Bold.eot');
    src: url('../fonts/poppins/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins/Poppins-Bold.woff') format('woff'),
        url('../fonts/poppins/Poppins-Bold.ttf') format('truetype'),
        url('../fonts/poppins/Poppins-Bold.svg#Poppins-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/poppins/Poppins-Regular.eot');
    src: url('../fonts/poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/poppins/Poppins-Regular.woff') format('woff'),
        url('../fonts/poppins/Poppins-Regular.ttf') format('truetype'),
        url('../fonts/poppins/Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: var(--poppins);
    font-size: 15px;
    line-height: 1.2em;
    color: var(--black);
    margin: 0;
    padding: 0;
}

.select-wrapper {
    position: relative;
}

.select-wrapper:after {
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #000;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    line-height: 1em;
}

.form-group {
    margin: 0 0 15px;
}

.form-group label {
    display: inline-block;
    margin-bottom: 7px;
    font-size: 14px;
    font-family: var(--poppins);
    font-weight: 500;
    color: var(--blue);
}

form .form-control,
form input[type="text"],
form input[type="email"],
form input[type="password"],
form textarea {
    width: 100%;
    outline: none;
    padding: 10px 15px;
    border-radius: 5px;
    font-family: var(--poppins);
    font-size: 14px;
    box-shadow: none;
    background-color: #fff;
    border-color: var(--borders);
    color: #000;
}

select.form-control {
    -webkit-appearance: initial;
    -moz-appearance: initial;
    appearance: initial;
}

.form-control:hover,
.form-control:focus,
input[type="text"]:hover,
input[type="email"]:hover,
input[type="password"]:hover,
textarea:hover {
    box-shadow: none !important;
    background-color: #fff;
    border-color: var(--borders);
    color: #000;
}

form .inputWithIcon .form-control .input[type="submit"],
.input[type="button"],
.btn {
    padding: 10px 30px;
    background-color: var(--blue);
    color: #000;
    font-size: 14px;
    font-family: var(--poppins);
    border-radius: 5px;
    border-color: var(--blue);
}

.input[type="submit"]:focus,
.input[type="button"]:focus,
.input[type="submit"]:hover,
.input[type="button"]:hover,
.btn:active,
.btn:focus,
.btn:hover {
    background-color: transparent;
    color: var(--white);
    border-color: var(--primary);
    box-shadow: none !important;
}

.btn.btn-small {
    padding: 10px 15px;
}

.btn.btn-dark {
    border-color: #000;
    background-color: #000;
    color: var(--primary);
}

.btn.btn-dark:hover,
.btn.btn-dark:focus {
    background-color: transparent;
    color: #000;
}

.btn.black-hover:focus,
.btn.black-hover:focus,
.btn.black-hover:hover {
    color: #000;
}

.btn.btn-danger {
    border-color: #000;
    color: #fff;
    background-color: var(--danger);
}

.btn.btn-outline {
    border-color: var(--primary);
    color: #fff;
    background-color: transparent;
}

.btn.btn-outline:hover {
    background-color: var(--primary);
    color: #000;
}

.form-control.error {
    border-color: var(--danger);
}

.info-text {
    width: 100%;
    display: inline-block;
    margin-top: 8px;
    font-size: 12px;
    color: var(--light);
    line-height: 1.3em;
    word-break: break-all;
}

.info-text.error {
    color: var(--danger);
}

.info-text a {
    color: var(--primary);
    text-decoration: underline;
}

.inputWithIcon {
    position: relative;
}

.inputWithIcon .form-control {
    padding-left: 30px;
}

.inputWithIcon i {
    position: absolute;
    top: 50%;
    left: 15px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

.inputWithIcon.right-side .form-control {
    padding-left: 15px;
    padding-right: 30px;
}

.inputWithIcon.right-side i {
    right: 15px;
    left: inherit;
}

.select-box {
    position: relative;
}

.select-box:after {
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 10px;
    content: "";
    background-color: #000;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}

.alert-dismissible .btn-close {
    font-size: 12px;
}

.alert p {
    font-size: 12px;
    margin: 0;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

.as-link {
    cursor: pointer;
}

.no-margin {
    margin: 0;
}

.fieldset {
    margin: 0 0 15px;
}

form label {
    font-size: 14px;
    color: var(--light);
    margin: 0 0 5px;
    display: inline-block;
    width: 100%;
    line-height: 1.2em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--poppins);
    font-weight: bold;
}

.mobile-only {
    display: none !important;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.loader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #192239;
    z-index: 10;
}

.loader-logo {
    position: relative;
}

.loader-logo img {
    max-width: 130px;
}

.loader-logo:after {
    width: 45px;
    height: 45px;
    content: "";
    background-image: url("../images/loader.gif");
    background-size: 100% 100%;
    position: absolute;
    top: 110%;
    left: 50%;

    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

.site-header {
    width: 100%;
    padding: 20px 0;
    background-color: var(--secondary);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;

    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.site-header.fixed {
    background-color: rgba(216, 231, 249, 0.8);
    padding: 15px 0;
}

.site-header .row {
    align-items: center;
}

.site-header .logo img {
    max-width: 110px;
    height: auto;
}

.navigation-wrapper {
    display: flex;
    flex-direction: column-reverse;
    text-align: right;
}

.navigation ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.navigation ul li {
    list-style: none;
    font-size: 11px;
    color: var(--white);
    border-bottom: 1px solid rgba(255, 255, 255, 0.35);
    margin-left: 15px;
    text-transform: uppercase;
}

.navigation ul li a {
    cursor: pointer;
    font-family: var(--poppins);
    font-weight: normal;
    color: white;
    text-decoration: none;
}

.social-wrapper .social-icons {
    margin-bottom: 10px;
}

.social-icons ul {
    margin: 0;
    padding: 0;
}

.social-icons ul li {
    display: inline-block;
    list-style: none;
    margin-right: 10px;
    font-size: 22px;
}

.social-icons ul li a {
    color: #fff;
}

/* Footer */
.footer-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    float: left;
    width: 100%;
    background: var(--blue);
}

.footer-wrapper .social-icons {
    justify-content: flex-end;
}

.footer-wrapper .social-icons ul {
    margin: 0;
    padding: 0;
    text-align: right;
}

.footer-wrapper .social-icons li {
    display: inline-block;
    margin-left: 10px;
    font-size: 22px;
}

.footer-wrapper .social-icons li a {
    color: #fff;
}

.footer-wrapper .copyright p {
    font-size: 14px;
    color: #fff;
    margin: 0;
    text-align: center;
}

.footer-wrapper .copyright a {
    color: #fff;
    text-decoration: none;
}

.footer-links {
    text-align: right;
}

.footer-links ul {
    margin: 0;
    padding: 0;
}

.footer-links ul li a {
    color: #fff;
    text-decoration: none;
    font-family: var(--poppins);
    font-size: 13px;
}

.back-to-top.show {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    transform: translateY(0);
}

.back-to-top {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--primary);
    color: #fff;
    position: fixed;
    bottom: 45px;
    right: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    z-index: 9;
    -webkit-transform: translateY(200%);
    -moz-transform: translateY(200%);
    transform: translateY(200%);
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.landing-page-wrapper {
    min-height: 100vh;
}

.landing-page-wrapper .header {
    padding: 25px 15px;
}


.headings {
    text-align: center;
    max-width: 767px;
    margin: 0 auto 30px;
}

.headings h2 {
    font-size: 36px;
    color: var(--blue);
    margin: 0 0 10px;
    line-height: 1.3em;
    font-family: var(--poppins);
}

.headings h3 {
    font-size: 22px;
    color: var(--blue);
    margin: 0 0 10px;
    line-height: 1.3em;
    font-family: var(--poppins);
}

.headings p {
    font-size: 22px;
    color: var(--blue);
    margin: 0 0 15px;
    line-height: 1.3em;
}


/* Login Auth Page */
.auth-page-wrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    background-image: url("../images/login-bg.png");
    background-size: cover;
    background-position: center;
    /* background-size: 70% auto;
    background-repeat: no-repeat;
    background-position: center left -30%; */
}

/* .auth-page-wrapper:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 1;
    background-image: url("../images/auth-shape.svg");
    background-size: 70% auto;
    background-repeat: no-repeat;
    background-position: center left -30%;
} */
.auth-page-wrapper:before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--blue);
    opacity: 0.85;
    z-index: 2;
}

.auth-page-container {
    position: relative;
    z-index: 3;
    padding: 45px 45px;
    background: rgba(216, 231, 249, 0.6);
    border-radius: 10px;
    width: 400px;
    max-width: 80%;
}

.auth-logo {
    text-align: left;
    margin-bottom: 15px;
}

.auth-logo img {
    max-width: 180px;
}

.auth-logo h3 {
    color: var(--blue);
    font-size: 18px;
    margin: 0;
    font-family: var(--poppins);
    font-weight: 600;
}

.auth-form form .form-control:focus,
.auth-form form .react-tel-input .form-control,
.auth-form form .form-control {
    width: 100%;
    height: auto;
    max-width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    color: var(--blue);
    padding: 8px 0 8px 0;
    font-size: 14px !important;
}

.auth-form form .form-control option {
    background-color: transparent;
    color: #000;
}

.auth-form form .react-tel-input .form-control {
    padding-left: 48px;
}

form .react-tel-input .form-control {
    padding-left: 48px;
    height: auto;
    width: 100%;
    max-width: 100%;
    line-height: 1.4em;
    font-size: 16px;
}

.auth-page-container .form-control::placeholder {
    color: #fff;
}

.auth-form .form-group {
    margin-bottom: 15px;
}

.auth-form .btn {
    margin-top: 10px;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.12em;
    padding: 7px 15px;
    color: #fff;
    font-weight: 600;
}

.auth-form .btn:focus,
.auth-form .btn:hover {
    background-color: transparent;
    border-color: var(--blue);
    color: var(--blue);
}

.auth-form form .form-label {
    font-size: 11px;
    line-height: 1.4em;
    color: #fff;
    margin: 0 0 5px;
}

form .react-tel-input .flag-dropdown.open .selected-flag,
form .react-tel-input .selected-flag:hover,
form .react-tel-input .selected-flag:focus,
form .react-tel-input .flag-dropdown:hover,
form .react-tel-input .flag-dropdown:focus,
form .react-tel-input .flag-dropdown.open,
form .react-tel-input .flag-dropdown {
    background-color: transparent;
    border: none;
}

.auth-form form .react-tel-input .selected-flag .arrow {
    border-top-color: #fff;
}

.auth-form form .react-tel-input .flag-dropdown.open .selected-flag .arrow {
    border-bottom-color: #fff;
}

form .react-tel-input .selected-flag .arrow {
    border-top-color: #000;
}

form .react-tel-input .flag-dropdown.open .selected-flag .arrow {
    border-bottom-color: #000;
}

.otp-wrapper {
    display: flex;
    justify-content: space-between;
}

.otp-wrapper .form-group {
    margin-right: 15px;
}

.otp-wrapper .form-group:last-child {
    margin-right: 0;
}

.otp-wrapper .form-control {
    text-align: center;
}

.update-number {
    width: 100%;
    font-size: 13px;
    display: inline-block;
    margin-top: 10px;
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
    line-height: 1.5em;
}

.social-icons {
    display: flex;
}

.social-icons .icons {
    margin: 5px 8px 5px 0;
    cursor: pointer;
    width: 20px;
    height: 20px;
    text-align: center;
}

.social-icons.light .icons {
    color: #fff;
}

.social-icons .icons.active {
    color: var(--primary);
}

.row-reverse {
    flex-direction: row-reverse;
}

.banner-wrapper {
    padding: 55px 0 85px;
    margin: 80px 0 0;
    background-color: var(--secondary);
}

.banner-wrapper .container {
    position: relative;
}

.banner-wrapper .row {
    align-items: center;
    position: relative;
    z-index: 1;
}

.banner-content h1 {
    font-size: 75px;
    margin: 0 0 25px;
    line-height: 1em;
}

.banner-content h1 span {
    color: var(--primary);
    width: 100%;
    display: inline-block;
}

.banner-content p {
    font-weight: 500;
    line-height: 1.4em;
    font-size: 23px;
    max-width: 80%;
}

.banner-img {
    text-align: center;
}

.banner-img img {
    max-width: 600px;
}

.banner-wrapper .circle-img {
    position: absolute;
    z-index: 0;
    bottom: -250px;
    left: 100px;
}

.banner-wrapper .circle-img img {
    max-width: 250px;
}

.banner-wrapper .container {
    position: relative;
}

.download-btn-wrapper {
    display: flex;
    align-items: center;
    margin: 30px 0 0;
}

.download-btn-wrapper .btn {
    background-color: var(--blue);
    border-color: var(--blue);
    border-radius: 45px;
    color: #fff;
    font-size: 17px;
    padding: 12px 35px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.download-btn-wrapper .social-icon {
    margin-left: 20px;
}

.download-btn-wrapper .social-icon img {
    max-width: 30px;
}

.about-wrapper {
    padding: 80px 0;
}

.about-wrapper .row {
    align-items: center;
    position: relative;
    z-index: 1;
}

.about-img img {
    max-width: 650px;
}

.about-content h2 {
    font-size: 36px;
    font-weight: 600;
    margin: 0 0 20px;
}

.about-content p {
    font-weight: 400;
    font-size: 22px;
    line-height: 1.7em;
    max-width: 450px;
}

.how-it-works-wrapper {
    padding: 80px 0 80px;
    position: relative;
    background-image: url("../images/bg-img.jpeg");
    background-position: center;
    background-size: cover;
}

@media(min-width:1025px) {
    .how-it-works-wrapper {
        background-attachment: fixed;
    }
}

.how-it-works-wrapper:after {
    content: "";
    background-color: var(--blue);
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.how-it-works-wrapper .container {
    position: relative;
    z-index: 2;
}

.how-it-works-wrapper .headings {
    margin-bottom: 55px;
}

.how-it-works-wrapper .headings p,
.how-it-works-wrapper .headings h2 {
    color: #fff;
}

.how-it-works-item {
    text-align: center;
}

.how-it-works-item img {
    max-width: 100%;
    margin: 0 0 25px;
    max-height: 500px;
}

.how-it-works-item h2 {
    font-weight: 600;
    font-size: 25px;
    margin: 0 0 10px;
    color: #fff;
}

.how-it-works-item p {
    font-size: 20px;
    line-height: 1.5em;
    color: #fff;
}

.faq-wrapper {
    padding: 60px 0 100px;
}

.faq-wrapper .headings {
    margin: 0 auto 55px;
}

.faq-wrapper .accordian {
    max-width: 768px;
    margin: 0 auto;
}

.accordian .card {
    border: none;
    border-bottom: 0.5px solid hsla(0, 0%, 50.2%, 0.15);
    border-radius: 0;
}

.accordian .card-header,
.accordian .card .collapse {
    position: relative;
}

.accordian .card-header:before {
    top: 30px;
    right: 45px;
    font-size: 20px;
}

.accordian .card-header:before,
.accordian .card-header a:before {
    position: absolute;
    top: 0;
    right: 0;
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: var(--primary-color);
}

.accordian .card-header a,
.accordian .card-header h5 {
    color: var(--primary-color);
    width: 100%;
    display: inline-block;
    padding-right: 30px;
    position: relative;
    text-decoration: none;
    margin: 0;
    cursor: pointer;
}

.accordion-header {
    font-family: var(--poppins);
    font-weight: 400;
}

.accordion-header .accordion-button:focus,
.accordion-header .accordion-button:not(.collapsed),
.accordion-header .accordion-button {
    font-family: var(--poppins);
    font-weight: 300;
    font-size: 19px;
}

.accordian .card-body,
.accordian .card-body p {
    font-family: var(--poppins);
    font-weight: 300;
    font-size: 18px;
    line-height: 1.6em;
}

.accordian .card-header {
    padding: 25px 15px;
    font-size: 18px;
    font-weight: 400;
    background-color: transparent;
    border-bottom: 0.5px solid hsla(0, 0%, 50.2%, 0.1);
}

.accordion-item {
    background-color: transparent;
    border: none;
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.15);
}

.accordion-button:focus,
.accordion-button:not(.collapsed),
.accordion-button {
    background-color: transparent;
    padding: 25px 55px 25px 15px;
    position: relative;
    color: #000;
    font-size: 18px;
    outline: none;
    border-color: rgba(128, 128, 128, 0.15);
    box-shadow: none;
    font-weight: 600;
    line-height: 1.4em;
}

.accordion-item .accordion-button:before {
    position: absolute;
    content: "\f067";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: var(--primary-color);

    top: 25px;
    right: 25px;
    font-size: 20px;
}

.accordion-item .accordion-button:after {
    display: none;
}

.accordion-button:not(.collapsed) {
    box-shadow: none;
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.15);
}

.accordion-button:not(.collapsed):before {
    content: "\f068";
}

.accordion-body {
    font-family: var(--poppins);
    font-weight: 300;
    font-size: 16px;
    line-height: 1.6em;
    padding: 20px 15px;
    color: #606060;
}

.accordion-body a {
    color: var(--gold);
}

.content-page-wrapper {
    margin: 140px 0 60px 0;
}

.content-page-wrapper h2 {
    font-size: 30px;
    line-height: 1.3em;
    margin: 0 0 15px;
}

.content-page-wrapper p {
    line-height: 1.4em;
    margin: 0 0 15px;
    font-size: 16px;
}

.content-page-wrapper ul {
    margin: 0;
    padding: 0;
}

.content-page-wrapper ul li {
    list-style: inside;
    line-height: 1.5em;
    margin: 0 0 10px;
    font-size: 16px;
}

.content-page-wrapper ul li ul {
    margin: 10px 0 0;
}

.content-page-wrapper ul li li {
    list-style: circle inside;
    margin: 0 0 10px 25px;
}